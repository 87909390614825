<template>
  <loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading :heading="customer.name"></page-heading>
      </column>
      <column class="is-narrow">
        <tabs>
          <tab-link name="customer-manager" :params="{ customer: customer.uuid }">Overview</tab-link>
          <tab-link name="customer-work-orders" :params="{ customer: customer.uuid }">Work Orders</tab-link>
          <tab-link name="customer-equipment" :params="{ customer: customer.uuid }">Equipment</tab-link>
          <tab-link name="customer-locations" :params="{ customer: customer.uuid }">Locations</tab-link>
          <tab-link v-if="!$root.isPassiveUser()" name="customer-contacts" :params="{ customer: customer.uuid }">Contacts</tab-link>
          <tab-link v-if="!$root.isPassiveUser()" name="customer-preferences" :params="{ customer: customer.uuid }">Preferences</tab-link>
        </tabs>
      </column>
    </columns>
    <router-view />
  </loader>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('customer/load', this.$route.params.customer)
    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('customer/clearCustomer')
  },

  computed: mapGetters('customer', [
    'customer'
  ])

}
</script>